import React from "react";

const CSS = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 256 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="256" height="256" rx="60" fill="#0277BD" />
      <path
        d="M53.7527 102.651L56.6155 134.593H128.096V102.651H53.7527Z"
        fill="#EBEBEB"
      />
      <path
        d="M128.095 38H127.985H48L50.9036 69.9423H128.095V38Z"
        fill="#EBEBEB"
      />
      <path
        d="M128.095 218.841V185.608L127.955 185.645L92.3813 176.04L90.1072 150.564H72.821H58.0425L62.5175 200.718L127.948 218.882L128.095 218.841Z"
        fill="#EBEBEB"
      />
      <path
        d="M167.318 134.593L163.61 176.019L127.985 185.635V218.866L193.468 200.718L193.948 195.321L201.454 111.229L202.233 102.651L208 38H127.985V69.9423H172.994L170.088 102.651H127.985V134.593H167.318Z"
        fill="white"
      />
    </svg>
  );
};

export default CSS;
