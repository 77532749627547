import React from "react";

const Photoshop = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      fill="none"
      viewBox="0 0 256 256"
    >
      <rect width="256" height="256" fill="#001E36" rx="60" />
      <path
        fill="#31A8FF"
        d="M57.6001 178.04V68.2799C57.6001 67.5332 57.9201 67.1066 58.6668 67.1066C60.4801 67.1066 62.1868 67.1066 64.6401 66.9999C67.2001 66.8932 69.8668 66.8932 72.7468 66.7866C75.6268 66.6799 78.7201 66.6799 82.0268 66.5732C85.3334 66.4666 88.5334 66.4666 91.7334 66.4666C100.48 66.4666 107.733 67.5332 113.707 69.7732C119.04 71.5866 123.947 74.5732 128 78.5199C131.413 81.9332 134.08 86.0932 135.787 90.6799C137.387 95.1599 138.24 99.7466 138.24 104.547C138.24 113.72 136.107 121.293 131.84 127.267C127.573 133.24 121.6 137.72 114.667 140.28C107.413 142.947 99.4134 143.907 90.6668 143.907C88.1068 143.907 86.4001 143.907 85.3334 143.8C84.2668 143.693 82.7734 143.693 80.7468 143.693V177.933C80.8534 178.68 80.3201 179.32 79.5734 179.427C79.4668 179.427 79.3601 179.427 79.1468 179.427H58.8801C58.0268 179.427 57.6001 179 57.6001 178.04ZM80.8534 87.6932V123.533C82.3468 123.64 83.7334 123.747 85.0134 123.747H90.6668C94.8268 123.747 98.9868 123.107 102.933 121.827C106.347 120.867 109.333 118.84 111.68 116.173C113.92 113.507 114.987 109.88 114.987 105.187C115.093 101.88 114.24 98.5732 112.533 95.6932C110.72 92.9199 108.16 90.7866 105.067 89.6132C101.12 88.0132 96.8534 87.3732 92.4801 87.4799C89.7068 87.4799 87.2534 87.4799 85.2268 87.5866C83.0934 87.4799 81.6001 87.5866 80.8534 87.6932Z"
      />
      <path
        fill="#31A8FF"
        d="M204.8 117.027C201.6 115.32 198.187 114.147 194.56 113.4C190.613 112.547 186.667 112.013 182.613 112.013C180.48 111.907 178.24 112.227 176.213 112.76C174.827 113.08 173.653 113.827 172.907 114.893C172.373 115.747 172.053 116.813 172.053 117.773C172.053 118.733 172.48 119.693 173.12 120.547C174.08 121.72 175.36 122.68 176.747 123.427C179.2 124.707 181.76 125.88 184.32 126.947C190.08 128.867 195.627 131.533 200.747 134.733C204.267 136.973 207.147 139.96 209.173 143.587C210.88 147 211.733 150.733 211.627 154.573C211.733 159.587 210.24 164.6 207.467 168.76C204.48 173.027 200.32 176.333 195.52 178.253C190.293 180.493 183.893 181.667 176.213 181.667C171.307 181.667 166.507 181.24 161.707 180.28C157.973 179.64 154.24 178.467 150.827 176.867C150.08 176.44 149.547 175.693 149.653 174.84V156.28C149.653 155.96 149.76 155.533 150.08 155.32C150.4 155.107 150.72 155.213 151.04 155.427C155.2 157.88 159.573 159.587 164.267 160.653C168.32 161.72 172.587 162.253 176.853 162.253C180.907 162.253 183.787 161.72 185.707 160.76C187.413 160.013 188.587 158.2 188.587 156.28C188.587 154.787 187.733 153.4 186.027 152.013C184.32 150.627 180.8 149.027 175.573 147C170.133 145.08 165.12 142.52 160.427 139.32C157.12 136.973 154.347 133.88 152.32 130.253C150.613 126.84 149.76 123.107 149.867 119.373C149.867 114.787 151.147 110.413 153.493 106.467C156.16 102.2 160.107 98.7866 164.693 96.6532C169.707 94.0932 176 92.9199 183.573 92.9199C187.947 92.9199 192.427 93.2399 196.8 93.8799C200 94.3066 203.093 95.1599 205.973 96.3332C206.4 96.4399 206.827 96.8666 207.04 97.2932C207.147 97.7199 207.253 98.1466 207.253 98.5732V115.96C207.253 116.387 207.04 116.813 206.72 117.027C205.76 117.24 205.227 117.24 204.8 117.027Z"
      />
    </svg>
  );
};

export default Photoshop;
