import React from "react";

const Redux = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 256 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="256" height="256" rx="60" fill="#764ABC" />
      <path
        d="M166.579 165.599C173.96 164.835 179.559 158.472 179.304 150.837C179.05 143.202 172.687 137.094 165.052 137.094H164.543C156.653 137.348 150.545 143.965 150.799 151.855C151.054 155.673 152.581 158.981 154.871 161.272C146.218 178.324 132.984 190.795 113.132 201.23C99.6432 208.356 85.6452 210.901 71.6472 209.12C60.1944 207.593 51.2866 202.502 45.6874 194.104C37.5431 181.633 36.7796 168.144 43.6513 154.655C48.487 144.983 56.1222 137.857 60.9579 134.294C59.9399 130.986 58.4128 125.386 57.6493 121.314C20.7456 148.038 24.5632 184.178 35.7615 201.23C44.1603 213.955 61.2124 221.845 80.046 221.845C85.1362 221.845 90.2264 221.336 95.3165 220.063C127.894 213.701 152.581 194.358 166.579 165.599V165.599Z"
        fill="white"
      />
      <path
        d="M211.372 134.04C192.03 111.388 163.525 98.9175 130.948 98.9175H126.876C124.585 94.3363 119.749 91.2822 114.405 91.2822H113.896C106.006 91.5367 99.8977 98.154 100.152 106.044C100.407 113.679 106.769 119.787 114.405 119.787H114.914C120.513 119.533 125.349 115.97 127.385 111.134H131.966C151.308 111.134 169.633 116.733 186.176 127.677C198.901 136.076 208.064 147.02 213.154 160.254C217.481 170.943 217.226 181.378 212.645 190.286C205.519 203.775 193.557 211.156 177.777 211.156C167.597 211.156 157.926 208.102 152.835 205.811C150.036 208.356 144.946 212.428 141.383 214.973C152.326 220.063 163.525 222.863 174.214 222.863C198.647 222.863 216.717 209.374 223.589 195.885C230.97 181.124 230.461 155.673 211.372 134.04Z"
        fill="white"
      />
      <path
        d="M82.0822 169.926C82.3367 177.561 88.6994 183.669 96.3347 183.669H96.8437C104.733 183.414 110.842 176.797 110.587 168.907C110.333 161.272 103.97 155.164 96.3347 155.164H95.8256C95.3166 155.164 94.5531 155.164 94.0441 155.419C83.6092 138.112 79.2826 119.278 80.8096 98.9177C81.8277 83.6472 86.9178 70.4127 95.8256 59.4689C103.206 50.0521 117.459 45.4709 127.13 45.2164C154.108 44.7074 165.561 78.3025 166.324 91.7915C169.633 92.555 175.232 94.3365 179.05 95.6091C175.996 54.3787 150.545 33 126.112 33C103.206 33 82.0822 49.543 73.6834 73.9759C61.976 106.553 69.6113 137.857 83.8637 162.545C82.5912 164.326 81.8277 167.126 82.0822 169.926V169.926Z"
        fill="white"
      />
    </svg>
  );
};

export default Redux;
