import React from "react";

const PostgreSQLDark = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      fill="none"
      viewBox="0 0 256 256"
    >
      <rect width="256" height="256" fill="#242938" rx="60" />
      <path
        fill="#336791"
        d="M203.48 148.688C183.16 152.878 181.684 145.995 181.684 145.995C203.15 114.143 212.129 73.7134 204.38 63.8204C183.259 36.8204 146.689 49.5962 146.077 49.9266L145.88 49.9618C141.414 49.0656 136.873 48.5924 132.317 48.5485C123.12 48.4079 116.145 50.9602 110.844 54.9751C110.844 54.9751 45.6078 28.1016 48.6453 88.7743C49.2922 101.684 67.1375 186.509 88.4422 160.845C96.2891 151.409 103.812 143.484 103.812 143.484C107.539 145.966 112.018 147.232 116.708 146.775L117.073 146.466C116.966 147.681 117.016 148.905 117.221 150.108C111.737 156.239 113.354 157.315 102.385 159.572C91.2898 161.857 97.8148 165.928 102.062 166.997C107.223 168.291 119.162 170.119 127.234 158.827L126.91 160.113C129.062 161.836 130.566 171.314 130.313 179.906C130.06 188.499 129.891 194.391 131.579 199.003C133.266 203.616 134.954 213.98 149.368 210.956C161.413 208.376 167.649 201.675 168.528 190.524C169.147 182.592 170.539 183.759 170.638 176.672L171.763 173.311C173.049 162.553 171.973 159.087 179.391 160.704L181.198 160.866C186.662 161.112 193.813 159.987 198.003 158.039C207.038 153.849 212.396 146.845 203.487 148.688H203.48Z"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5.034"
        d="M146.07 48.5961C143.82 49.2993 182.38 34.4985 204.303 62.504C212.038 72.3969 203.073 112.827 181.606 144.678M128.098 153.784C127.536 173.795 128.239 193.946 130.194 198.784C132.148 203.621 136.346 213.198 150.767 210.118C162.812 207.538 167.199 202.538 169.098 191.506L173.548 156.231L128.098 153.784ZM110.816 53.4688C110.816 53.4688 45.5375 26.7782 48.575 87.4508C49.2219 100.36 67.0672 185.185 88.3719 159.521C96.1484 150.148 103.18 142.815 103.18 142.815L110.816 53.4688Z"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="bevel"
        strokeWidth="5.034"
        d="M181.613 144.664C181.613 144.664 183.02 151.555 203.41 147.35C212.319 145.508 206.954 152.511 197.926 156.702C190.515 160.147 173.893 161.026 173.619 156.28C172.916 144.01 182.366 147.737 181.684 144.664C181.065 141.894 176.832 139.18 174.048 132.402C171.608 126.495 140.579 81.1438 182.654 87.8727C184.201 87.5563 171.685 47.7946 132.31 47.2181C92.9352 46.6415 94.1797 95.6282 94.1797 95.6282"
      />
      <path
        stroke="#fff"
        strokeLinejoin="round"
        strokeWidth="5.034"
        d="M117.08 148.869C111.596 155 113.213 156.076 102.245 158.333C91.1492 160.618 97.6742 164.689 101.921 165.758C107.082 167.052 119.021 168.88 127.093 157.58C129.554 154.135 127.079 148.651 123.704 147.259C122.073 146.584 119.893 145.74 117.095 148.876L117.08 148.869Z"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5.034"
        d="M116.722 148.763C116.159 145.156 117.903 140.874 119.766 135.854C122.565 128.331 129.02 120.807 123.859 96.9219C120.013 79.1329 94.1867 93.2165 94.1867 95.6282C94.1867 98.0399 95.3539 107.863 93.7648 119.288C91.6766 134.209 103.257 146.823 116.588 145.536"
      />
      <path
        fill="#fff"
        stroke="#fff"
        strokeWidth="1.68"
        d="M110.577 95.2695C110.457 96.0922 112.088 98.293 114.205 98.5883C116.321 98.8836 118.128 97.168 118.248 96.3383C118.367 95.5086 116.736 94.6086 114.62 94.3133C112.503 94.018 110.682 94.4539 110.577 95.2695V95.2695Z"
      />
      <path
        fill="#fff"
        stroke="#fff"
        strokeWidth=".837"
        d="M175.011 93.5891C175.123 94.4118 173.499 96.6125 171.383 96.9079C169.266 97.2032 167.445 95.4875 167.34 94.6579C167.234 93.8282 168.852 92.9282 170.968 92.6329C173.084 92.3375 174.905 92.7735 175.011 93.5891Z"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5.034"
        d="M182.577 87.8867C182.928 94.3555 181.184 98.75 180.959 105.634C180.636 115.632 185.727 127.079 178.055 138.54"
      />
    </svg>
  );
};

export default PostgreSQLDark;
