import React from "react";

const Discord = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      fill="none"
      viewBox="0 0 256 256"
    >
      <rect width="256" height="256" fill="#5865F2" rx="60" />
      <g clipPath="url(#clip0_158_82)">
        <path
          fill="#fff"
          d="M197.308 64.7966C184.561 58.9476 170.892 54.6383 156.599 52.1701C156.339 52.1225 156.079 52.2415 155.945 52.4796C154.187 55.6064 152.239 59.6856 150.876 62.8918C135.503 60.5904 120.21 60.5904 105.153 62.8918C103.789 59.6143 101.771 55.6064 100.005 52.4796C99.8707 52.2495 99.6107 52.1305 99.3504 52.1701C85.0659 54.6304 71.3963 58.9397 58.6411 64.7966C58.5307 64.8442 58.4361 64.9236 58.3732 65.0267C32.4449 103.763 25.3421 141.547 28.8265 178.863C28.8423 179.046 28.9447 179.22 29.0867 179.331C46.1934 191.894 62.7642 199.521 79.0273 204.576C79.2876 204.655 79.5634 204.56 79.729 204.346C83.5761 199.092 87.0054 193.553 89.9456 187.728C90.1192 187.386 89.9535 186.982 89.5989 186.847C84.1594 184.783 78.98 182.268 73.9977 179.411C73.6037 179.181 73.5721 178.617 73.9347 178.347C74.9831 177.561 76.0318 176.744 77.033 175.919C77.2141 175.768 77.4665 175.736 77.6794 175.831C110.411 190.775 145.846 190.775 178.191 175.831C178.404 175.728 178.657 175.76 178.846 175.911C179.847 176.736 180.895 177.561 181.952 178.347C182.314 178.617 182.291 179.181 181.897 179.411C176.914 182.323 171.735 184.783 166.288 186.839C165.933 186.974 165.775 187.386 165.949 187.728C168.952 193.545 172.381 199.084 176.157 204.338C176.315 204.56 176.599 204.655 176.859 204.576C193.201 199.521 209.772 191.894 226.879 179.331C227.028 179.22 227.123 179.053 227.139 178.871C231.309 135.73 220.154 98.2554 197.568 65.0346C197.513 64.9236 197.419 64.8442 197.308 64.7966ZM94.8335 156.142C84.9792 156.142 76.8594 147.095 76.8594 135.984C76.8594 124.873 84.8217 115.826 94.8335 115.826C104.924 115.826 112.965 124.953 112.807 135.984C112.807 147.095 104.845 156.142 94.8335 156.142ZM161.29 156.142C151.435 156.142 143.316 147.095 143.316 135.984C143.316 124.873 151.278 115.826 161.29 115.826C171.38 115.826 179.421 124.953 179.264 135.984C179.264 147.095 171.38 156.142 161.29 156.142Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_158_82">
          <rect
            width="200"
            height="154.93"
            fill="#fff"
            transform="translate(28 51)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Discord;
